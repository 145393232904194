import PropTypes from "prop-types";
import { useQuery } from "@apollo/client/react/hooks";

import { addApolloState, initializeApollo } from "lib/apolloClient";

import PageLayout from "components/shared/pageLayout/PageLayout";
import LandingPage from "components/landingPage/LandingPage";

import { GetLandingPage } from "../lib/api/cc/queries/landingPage.gql";

function Home({ slug }) {
    const { data, loading } = useQuery(GetLandingPage, {
        variables: { slug },
        skip: !process.env.featureFlags.cmsData
    });
    const page = data?.landingPage;
    const metadata = page?.metadata;

    return (
        <PageLayout
            title={metadata?.title}
            description={metadata?.description}
            keywords={metadata?.keywords}
            canonical={metadata?.canonical}
            showMobileLogo={false}
            desktopLogoColor="full-color"
        >
            <LandingPage loading={loading} data={page} />
        </PageLayout>
    );
}

export async function getStaticProps() {
    const slug = "index";

    if (!process.env.featureFlags.ssr || !process.env.featureFlags.cmsData) {
        return { props: { slug } };
    }

    const apolloClient = initializeApollo();

    try {
        await apolloClient.query({
            query: GetLandingPage,
            variables: { slug },
            context: { timeout: 9000 }
        });
    } catch (error) {
        throw new Error(
            `Error on getStaticProps() for "${slug}": ${error.message}`
        );
    }

    return addApolloState(apolloClient, { props: { slug }, revalidate: 60 });
}

Home.propTypes = {
    slug: PropTypes.string.isRequired
};

export default Home;
